.styled-textfield {
    .MuiOutlinedInput-root {
      fieldset {
        border-color: #ccc; 
      }
      &:hover fieldset {
        border-color: #ccc;
      }
      &.Mui-focused fieldset {
        border-color: #ccc; 
      }
    }
  }

.styled-textfield-width {
    width: 225px !important;
}