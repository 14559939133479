.table-container .MuiDataGrid-root .MuiDataGrid-cell {
  border: 1px solid #ccc;
  padding: 2px;
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word !important;
  white-space: normal !important;
  
}

.table-container .MuiDataGrid-root .MuiDataGrid-columnHeader {
  border: 1px solid #ccc;
  word-wrap: break-word !important;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
  position: sticky;  
  top: 0;  
  z-index: 1000; 
  
}

.table-container .MuiDataGrid-columnHeaderTitle {
  font-weight: bold;
  position: relative;
  
}

.table-container .MuiDataGrid-root {
  border: 2px solid black;
  border-radius: 4px;
  border: 2px solid black;
  border-radius: 4px;
  overflow-x: auto; 
  overflow-y: auto; 
  max-height: 70vh; 
}

.table-container .MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-left: 1px solid #ccc;
}

.table-container .MuiDataGrid-root .MuiDataGrid-footerContainer {
  min-height: 0px;
  border: none;
}

.table-container .MuiDataGrid-root .MuiDataGrid-selectedRowCount {
  padding: 15px;
  margin: 10px;
  font-size: 14px;
  color: #333;
  border-radius: 4px;
}

.table-container .MuiDataGrid-root .MuiDataGrid-row.selected-row {
  background-color: #b4cccc;
  color: black;
}

.red-header {
  background: linear-gradient(360deg, rgb(231, 231, 231), transparent);
  color: rgb(0, 0, 0);
}


@media (max-width: 700px) {
  .table-container {
    height: auto; 
    width: 100%;  
    margin-left: 5px;
    margin-right: 10px;
  }
  
  
  
  .table-container .MuiDataGrid-root .MuiDataGrid-columnHeader {
    padding: 4px;  
  }
}

@media (max-width: 691px) {
  .table-container {
    margin-left: 0;
    min-width: 200px; 
  }


  .table-container .MuiDataGrid-root .MuiDataGrid-columnHeader {
    padding: 3px;
  }
}
