@import 'src/Pages/color_var.scss';
.sidebar {
  width: 172px;
  position: fixed;
  height: 100%;
  overflow: auto;
  border: none;
  left: 0;
  top: 0;
  background: linear-gradient(to bottom, #30dcff, #1be0d9, #0df0ee);}
.sidebar a {
  display: block;
  color: black;
  font-weight: bold;
  font-size: 20px;
  padding: 16px;
  text-decoration: none;
  background: linear-gradient(to bottom, #21d3f6, #1be0d9, #5ce4f3);}
.sidebar a.active {
  background-color: #ccc;
  color: $lighter;
}
.sidebar a:hover:not(.active) {
  background-color: $slightLight;
  color: $lighter;
}
div.content {
  margin-left: 200px;
  padding: 1px 16px;
  height: 1000px;
}
.topnav {
  overflow: hidden;
  background-color: $slightLight;
  position: relative;
}
.topnav #myLinks {
  display: none;
}
.topnav a {
  color: $fontColor;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
}
.topnav a.icon {
  background: $fontColor;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
}
.topnav a:hover {
  background-color: $lighter;
  color: black;
}
.active:not([id^=controlled-tab]) {
  background-color: $lighter;
  color: $fontColor;
}
.highlight {
  background: #b3fbf0 !important;
}
.navbar-brand {
  padding: 0px !important;
}
@media screen and (max-width: 600px) {
  .topnav {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    background: linear-gradient(to bottom, #18bdde, #1be0d9, #5cf3d2);
    height: 60px;
  }
  .sidebar {
    margin-left: 0;
  }

  .navbar-brand {
    margin: 0;
    padding: 10px 0;
  }

  header {
    margin: 0;
    padding: 0;
  }
}
