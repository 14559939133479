.heading {
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  align-items: center;
  margin-left:180px;
  position: relative;

.title-align {
  background: linear-gradient(360deg, rgb(211, 211, 211), transparent);
  border-radius: 6px;
  border-style: outset;
  margin-bottom: 10px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.title-with-icon {
  display: flex;
  align-items: center;
  gap: 8px;
}

.left-content {
  display: flex;
  align-items: center;
}

.right-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  text-align: center;
  flex: 1;
}
}
@media (max-width: 600px) {
  .right-content {
    display: none;
  }
  .heading {
    margin-left: 0px;
  }
}
@media (max-width: 768px) {
  .heading {
    flex-direction: column;
    align-items: flex-start;

    .title-align {
      h6 {
        font-size: 1rem;
        text-align: left;
        transform: none;
        position: relative;
        left: 0;
      }
    }
  }
}
