.pagination-container {
   margin-left:'700px'
}
@media (max-width: 768px) {
    .pagination-container {
        margin-top: 300px;
    }
}
@media (max-width: 480px) {
    .pagination-container {
        margin-top: 100px;
        margin-left: -100px;
        margin-right: -10px;
    }
}

.element {
    margin-top: 10px;  
    margin-right: 40px; 
}
@media (max-width: 688px) { 
  .element {
      margin-left: 80px; 
      margin-right: 10px;
      margin-top: -5px; 
  }
}
@media (max-width: 650px) { 
    .element {
        margin-left: 20px; 
        margin-right: 10px;
        margin-top: -5px; 
    }
}

.search-button {
  margin-left: 60px;
  margin-top: 0px;
  background-color: #68E0CF !important;
  color: #0e0d0dda !important;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 14px; 
}
@media (max-width: 964px) {
  .search-button{
    margin-left: 0;
    margin-top: 10px;
    width: 40px   !important; 
    height: 30px  !important;
    font-size: 10px !important; 
  }
}
@media (max-width: 888px) {
 .search-button{
    margin-left: 0;
    margin-top: 10px;
    width: 40px !important;
    height: 30px!important;
    font-size: 8px !important; 
  }
}

.styled-textfield1 {
  @media (max-width: 960px) {
    max-width: 180px;
    margin-left: 50px;
  }
  @media (max-width: 888px) {
    max-width: 150px;
    margin-left: 30px;
  }
  @media (max-width: 810px) {
    max-width: 110px;
    margin-left: 5px;
  }
  @media (max-width: 760px) {
    max-width: 90px;
    margin-left: -10px;
  }
}

.styled-box {
  margin-left: 180px;
  @media (max-width: 1000px) {
    margin-left: 50px;
  }
 @media (max-width: 900px) {
    margin-left: 0;
  }
  @media (max-width: 760px) {
    margin-left: -20px;
  }
  @media (max-width: 600px) {
    margin-left: 0;
  }
}

.responsive-div {

  margin-right: 20px;
  margin-top: 75px; 

}
@media (max-width: 1200px) {
  .responsive-div {
    margin-left: 180px;  
    margin-top: 75px;
  }
}
@media (max-width: 700px) {
  .responsive-div {
    margin-left: 170px;
    margin-top: 75px;
  }
}
@media (max-width: 650px) {
  .responsive-div {
    margin-left: 20px;
    margin-top: 75px;
  }
}

.driver-icons-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-left: 8px;  
  margin-right: 2px; 
}

.lead-icons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px; 
  margin-top: 7px;
}

.general-icons-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; 
  margin-top: 7px;
}




  