$backgroundColor: var(--background-color);
$light: var(--light);
$lighter: var(--lighter);
$slightLight: var(--slightLight);
$fontColor: var(--fontColor);
$bkgImg: var(--bkgImg);
:export {
  backgroundColor: $backgroundColor;
  light: $light;
  slightLight: $slightLight;
  lighter: $lighter;
  fontColor: $fontColor;
  bkgImg: $bkgImg;
}