.body {
  margin: 0;
  background: url('../../assets/app_bg.png') center center/cover no-repeat fixed;
  font-family: 'Arial', sans-serif;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.login-form {
  background: url('../../assets/app_bg_gradient.png') center center/cover no-repeat fixed;
  padding: 20px;
  border-radius: 10px;
  max-width: 800px;
  text-align: center;
  width: 600px;
  position: relative;
  top: 50%;
  left: 50%;
  margin-top: 300px;
  transform: translate(-50%, -50%);
  justify-content: center;
}
.logo-container {
  margin-bottom: 20px;
}
.app-logo {
  width: 140px;
  height: auto;
}
.input-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}
.input-digit {
  width: 2em;
  height: 2em;
  text-align: center;
  margin-right: 0.7em;
  font-size: 16px;
}
.otp-label {
  position: relative;
}
input {
  flex: 1;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}
.country-code-input {
  display: inline-block; 
}
.country-code-input input {
  width: 42px; 
  height: 2.6em;
  box-sizing: border-box; 
}
.login-button {
  margin-top: 10px;
    width: 16.5em;
    height: 2.3542em;
    border-radius: 5px;
    background-color: #d0f87e;
    font-size: larger;
    border: none;
    cursor: pointer;
}
.partition {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.outer-circle {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #2a2b4c;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.or-text {
  margin: 0;
  font-size: 10px;
  color: white;
}
.partition-line {
  width: 100%;
  height: 0.4px;
  background-color: #696b75;
  position: absolute;
  top: 77.2%;
  transform: translateY(-50%);
  z-index: 0;
}
.get-otp-button {
  margin-right: 20px;
  background-color: #2a2b4c;
  cursor: pointer;
  border-radius: 1em;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.otp-heading
{color: white;
  font-size: 16px;

}
.footer{
  background-color: #defffa;
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    color: black;
}
.footer .a{
  text-decoration: none;
}
p{
  margin-bottom: 0 !important;
}
.error-message{
  color: red;
}