nav{
background: linear-gradient(to bottom, #18bdde, #1be0d9, #5cf3d2);
position: fixed;
}
.navbar{
  position: fixed;
    margin-top: 0px;
    width: 100%;
    z-index: 1;
    height: 60px;
    margin-left:172px;
  }
.dropdown-toggle {
    white-space: nowrap;
    margin-right: 50px;
}
.dropdown{
  margin-left: 100px;
  margin-right: 50px;

}
.navbar-dropdown {
  position: fixed;
  justify-content: flex-end !important;
  left: 0;
  top:0;
}
