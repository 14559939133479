.custom-button{
  background-color: #68E0CF !important;
  color: #0e0d0dda  !important;
}
.custom-button:hover {
  background-color: #68E0CF !important;
}

.custom-cancel-button {
  background-color: transparent;
  color: #0e0d0dda !important;
  border: 1px solid #0e0d0dda !important;
}
.custom-cancel-button:hover {
  background-color: #f5f5f5 !important;
  border-color: #0e0d0dda !important;
}
