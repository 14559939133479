.tabs {
    background-color: #68E0CF;
    & .MuiTabs-indicator {
      background-color: #68E0CF;
    }
    .tab {
      min-width: 5px;
      color: #0e0d0dda;
      text-transform: capitalize;
      margin-right: 0; 
      &.Mui-selected {
        color: #0e0d0dda;
      }
     &:hover {
        background-color: #fff;
        color: #0e0d0dda;
      }
    }
    & .tab:last-child {
      margin-right: 0 !important; 
    }
   .tab-selected {
      background-color: #fff;
    }
   .tab-default {
      background-color: #68E0CF;
    }
    & .MuiTabs-flexContainer {
      justify-content: space-between; 
      gap: 0; 
    }
}
  