.modal-header {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0;
    font-size: 1.2rem;
    font-weight: bold;
}
.form-section {
   padding: 20px 0;
  .form-group {
      display: flex;
      
      margin-bottom: 13px;
      gap: 80px;
  
      .MuiTextField-root {
        width: 50%;
      }
    }
}
.form-section-additional {
    padding: 10px 0;
   .form-group-additional {
       display: flex;
       margin-bottom: 13px;
       gap: 80px;
       .MuiTextField-root {
         width: 50%;
       }
     }
}
.form-section-icon {
    padding: 10px 0;
    display: flex;
    flex-direction: column;
    gap: 20px; 
}
.form-group-icon {
    display: flex;
    flex-direction: column;
    gap: 15px; 
}
.file-input-icon {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    gap: 15px; 
}
  

  
  
  
  